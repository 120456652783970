body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.next-level-container {
  background-color: #f37021;
  max-width: 1080px;
  margin: 120px auto;
  height: 400px;
  border-radius: 40px;
  background-image: url(./assets/next-level-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.next-level-content {
  padding: 150px 90px;
}
.next-level-img-container {
  padding: 31px 76px;
}

@media only screen and (max-width: 900px) {
  .next-level-container {
    margin: 120px 40px;
  }
  .next-level-content {
    padding: 40px 30px;
  }
  .next-level-img-container {
    padding: 21px 25px;
  }
}

@media only screen and (max-width: 768px) {
  .next-level-container {
    text-align: center;
    justify-content: center;
    border-radius: 0px;
    margin: 0;
  }
  .next-level-img-container {
    display: none;
  }
}
.next-level-h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 48px;
}
.next-level-button {
  background: #ffffff;
  border-radius: 4px;
  color: #f37021;
  border: none;
  padding: 16px 36px;
  font-size: 18px;
  line-height: 24px;
}

.next-level-button:hover {
  color: white;
  background: #f37021;
  border: 1px solid white;
}
