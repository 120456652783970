@media only screen and (max-width: 375px) {
  .hide-in-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 376px) {
  .hide-in-desktop {
    display: none !important;
  }
}
